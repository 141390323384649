<template>
    <section :style='{ paddingTop: "2rem", minHeight: "35rem" }'>
        <div class='parent' id='users'>
            <template v-if='role === "admin"'>
                <h3>יצירת משתמש חדש</h3>
                <div id='create'>
                    <span class='material-icons-outlined' v-if='!creatingUser' @click='createUser("subjects")'>person_add_alt</span>
                    <div class='creatingUser' v-else><div></div><div></div><div></div><div></div></div>
                    <input id='link' v-model='link' />
                    <button no-styling class='material-icons-round' id='copy' @click='copyLink'>
                        content_copy
                    </button>
                </div>
                <div v-if='uid' class='showUid'>{{ uid }}</div>
            </template>
            <h3>סיבוב נסיון</h3>
            <div id='testRun'>
                <span class='material-icons-round' @click='testRun'>play_arrow</span>
            </div>
            <template v-if='role === "admin"'>
                <h3>צפייה בפרטי משתמש קיים</h3>
                <div>
                    <span class='material-icons-round' @click='testUser'>visibility</span>
                    <input v-model='viewLink'/>
                </div>
                <div v-if='loadingUser'><img src='/images/loading.gif' /></div>
                <div v-if='loaded.token===false'>לא נמצא משתמש שתואם את הקישור</div>
                <div v-else-if='!loadingUser && loaded.token!==null'>
                    <a v-if='loaded.used' :href='`/pdf/${loaded.token}/D`'><img src='/images/pdf.png' /></a>
                    <div class='id'><img src='/images/hash.png' />{{ loaded.userId }}</div>
                </div>
            </template>
            <template v-if='!["viewer", null].includes(role)'>
                <h3>הורדת קובץ CSV</h3>
                <div class='csvLink'>
                    סוג הCSV:
                    <select v-model='csvType'>
                        <option value='records'>הכל כטקסט</option>
                        <option value='optionsasnumbers'>אפשרויות כמספרים</option>
                        <option value='getoptions'>רשימת אפשרויות</option>
                        <option value='dror'>מותאם לדרור</option>
                    </select>
                    <span class='material-icons-round' @click='makeCSV'>download</span>
                </div>
            </template>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import randomString from 'random-crypto-string';

import { collection, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { setToast } from '@/store';
import { getAuth, signOut } from '@firebase/auth';
import { getRole } from '../helpers';
import makeCSV, { CSVType } from './makeCSV';

export default defineComponent({
    data: () => ({
        link: '',
        uid: null as string | null,
        creatingUser: false,
        viewLink: '',
        loaded: {
            token: null,
            userId: null,
            used: null,
        },
        loadingUser: false,
        csvType: 'records' as CSVType,
    }),
    computed: { role: getRole },
    methods: {
        async testRun(){
            await this.createUser('tests');
            signOut(getAuth());
            window.location.href = this.link;
        },
        async createUser(countKey: 'subjects' | 'tests' = 'subjects'){
            this.link = ''; this.uid = null;

            try{
                this.creatingUser = true;
                const subjectsRef = collection(getFirestore(), 'subjects');
                const autoSavesRef = collection(getFirestore(), 'autoSaves');
                const countRef = doc(subjectsRef, 'count');
                const count = await getDoc(countRef).then(snap => ++snap.data()![countKey]);

                const token = await randomString(30);
                const id = this.uid = (countKey === 'tests' ? 't' : '') + (10000 + count).toString() + Math.random().toString().slice(2, 5);
                await Promise.all([
                    updateDoc(countRef, { [countKey]: count }),
                    setDoc(doc(subjectsRef, id), { records: [] }),
                    setDoc(doc(autoSavesRef, id), { records: [] }),
                    setDoc(doc(collection(getFirestore(), 'tokens'), token), {
                        id,
                        usedAt: null,
                        createdAt: Date.now()
                    })
                ]);

                this.link = `${window.location.origin}/token/${token}`;
            }catch(e){
                console.error("Error adding document: ", e);
                this.link = 'ERROR';
            }finally{
                this.creatingUser = false;
            }
        },
        async testUser(){
            const token = this.viewLink.split('/').slice(-1)[0];

            this.loadingUser = true;
            const tokenRef = doc(collection(getFirestore(), 'tokens'), token);
            const user = await getDoc(tokenRef).then(snap => snap.data());
            this.loadingUser = false;
            
            Object.assign(this.loaded, user ? { token, userId: user.id, used: user.usedAt !== null } : { token: false });
        },
        makeCSV(){
            makeCSV(this.csvType)
        },
        async copyLink(){
            const text = `זה הקישור לשאלון שלך. תודה על השתתפותך 🦋\n\r${this.link}\n\rנתקלת בבעיות טכניות במהלך מילוי השאלון? ניתן לפנות אלינו לעזרה.\nשימו לב: הקישור הוא אישי לכל נבדק. מכירים אנשים נוספים שמעוניינים למלא את השאלון? הפנו אותם אל coronastudy.co.il/whatsapp`
            await navigator.clipboard.writeText(text);
            setToast({ text: 'ההודעה הועתקה בהצלחה', type: 'success' })
        }
    }
});
</script>

<style lang="scss">
.creatingUser {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 1.2rem;

    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        margin: 2px;
        border: 2px solid #aaa;
        border-radius: 50%;
        animation: creatingUser 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #aaa transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes creatingUser {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

.csvLink {
    font-size: 14px;
    @include flexbox(center-all);
    transform: translateX(.2rem) !important;

    & select {
        margin: 0 0.5rem 0 1rem;
        font-size: 14px;
        padding: 0.2rem 0.4rem;
        border-radius: $corner;
    }

    & .material-icons-round {
        font-size: 2rem !important;
    }
}

#testRun {
    transform: none !important;
    & span {
        margin-top: -1rem;
        font-size: 3rem;
        cursor: pointer;
        color: #155667 !important;
    }
}

.showUid {
    color: #155667;
    margin: -.5rem auto .5rem;
}

#users {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    width: 30rem;
    @include flexbox(center-all);
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    & h3 {
        background-color: $swatchA;
        @include shadow;
        border-radius: $corner;
        width: fit-content;
        padding: 0.5rem 1rem;
        margin: 1rem 0;
        min-width: 50%;
        font-weight: normal;
    }

    & > div {
        @include flexbox(center-all);
        padding: 1rem;
        width: 85%;
        transform: translateX(1rem);

        &:nth-child(3) {
            padding: 0.5rem 1rem 0;
        }

        & input {
            font-size: 0.5rem;
            flex: 1;
            padding: 0.25rem;
            &::selection{
                background: #96b7d6;
                color: black;
            }
        }
        & > [class^="material-icons"]:not(button) {
            cursor: pointer;
            margin-left: 1rem;
            color: #4985bd;
            transition: scale .1s, color .1s;
            
            &:hover {
                color: #1d5c97;
                scale: 1.1;
            }
        }
        & button {
            display: inline-flex;
            padding: 0.1rem;
            & img {
                max-width: 1rem;
                margin: auto;
            }
        }
    }

    & img {
        max-width: 2rem;
        margin-right: 1rem;
        cursor: pointer;
    }
    & .id {
        @include flexbox(center-all);
        margin-right: 3rem;
    }
}

#create {
    & #copy {
        height: 2rem;
        border-radius: $corner 0 0 $corner;
        border: $borderLight;
        border-right: none;
        @include shadow;
        position: relative;
        z-index: 1;
        padding: 0 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 1.2rem;
        margin: 0;
    }

    & input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        z-index: 2;
    }
}
</style>