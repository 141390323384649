import { parser } from "@/helpers";
import { format } from "date-fns";
import { getAllRecords, getQuestionsFromQuestionnaires } from "../helpers";

function download(filename: string, text: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}

export type CSVType = 'records' | 'optionsasnumbers' | 'getoptions' | 'dror';
const CRLF = '\r\n';
const finalize = (csv: string, type: CSVType) => download(`study-${type}-${format(Date.now(), 'ddMMY-kk:mm')}.csv`, csv);
const makeOptionsCSV = async () => {
    const questions = await getQuestionsFromQuestionnaires();

    let columnCount = 0;
    let csv = '';
    questions.forEach(question => {
        if(!question.options) return;

        csv += `"${parser(question.text)}"`;
        const optionCount = Object.keys(question.options).length;
        if(columnCount < optionCount)
            columnCount = optionCount;

        Object.values(question.options).forEach(option => {
            if(typeof option === 'string') // This is true for any option except "other"
                csv += `,"${parser(option)}"`;
        });
        csv += CRLF;
    });
    
    const columns = new Array(columnCount).fill(true).map((_, index) => `"אפשרות ${index}"`);
    columns.unshift(`"שאלה"`);

    return finalize(columns.join(',') + CRLF + CRLF + csv, 'getoptions');
}

const makeCSV = async (type: CSVType) => {
    if(type === 'getoptions')
        return makeOptionsCSV();

    // eslint-disable-next-line prefer-const
    let { records, questions } = await getAllRecords({ merge82: true });
    if(!questions || !records) return;
   
    if(type === 'dror'){
        /* Setting up questions to be ignored for Dror's purposes */
        const omit = [2, 3, 5, 123, 124, 125, 126, 127, 128, 129, 130, 'c16', 'c17', 'c18', 'c19', 131, 132, 19, 15, 'c20', 137, 'c21', 138, 139, 83, 22, 133, 134, 135, 79].concat([85, 86, 87, 88, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 116, 'c4', 117]);
        omit.unshift(82);
        questions = questions.filter(question => !omit.includes(question.id));
    }

    const rows = records.map(({ responses, createdAtFormatted, age, sex, agreedToShare, uid }) => {
        const row = [uid, createdAtFormatted, age, sex, agreedToShare];
        questions!.forEach(question => {
            if(question.type === 'response') return;
            const response = responses[question.compoundId!];
            
            if(!response?.valueText){
                return row.push('');
            }else{
                const value = !['dror', 'optionsasnumbers'].includes(type)
                    ? response.valueText//.replace('"', '""')
                    : (Array.isArray(response.value) ? response.value.join(', ') : response.value);
                return row.push(value);
            }
        });
        return row;
    });

    const prop = window.location.search === '?ids' ? 'id' : 'text';
    const columns = questions.filter(q => q.type !== 'response').map(q => q[prop])
    columns.unshift('id', 'timestamp', 'age', 'sex', 'agreedToShare');
    rows.unshift(columns);

    const { unparse } = await import('papaparse');
    finalize(unparse(rows, { quotes: true }), type);
}

export default makeCSV;